import React, {  useState } from 'react';
import "../Styles/HeaderStyles.css";
import { Box, Collapse, Divider, Drawer, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Typography,Grid, Modal } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { NavLink ,Link,useLocation} from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
// ------------------------------------
import Slide from '@mui/material/Slide';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useMemo } from 'react';


const Header = () => {
  const whatsapp = 'Whatsapp';
  const sms = 'SMS';
  const [formData, setFormData] = useState({
    name: '',
    number: '',
    option: ''
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.name ||  !formData.number || !formData.option) {
      alert('Please fill in all fields.');
      return;
    }
    showPleaseWaitMessage();
  
    const formDataToSubmit = new FormData();
    formDataToSubmit.append('name', formData.name);
    formDataToSubmit.append('number', formData.number);
    formDataToSubmit.append('option', formData.option);
  
    fetch('https://script.google.com/macros/s/AKfycbx-9geeXeTRL9HOu8Y-KanDccgSZo6EXU4tggwnYVaXvUdgYvPuZA-AGb_W0LpThjES/exec', {
      method: 'POST',
      body: formDataToSubmit
    })
    .then(response => response.text())
    .then(data => {
      console.log(data); // Handle response from server if needed
      alert('Form submitted successfully!');
      setFormData({ name: '', number: '', option: ''});
      // setShowForm(false)
           handleDialogClose();

    })
    .catch(error => {
      console.error('Error:', error);
      alert('An error occurred while submitting the form.');
    })
    .finally(() => {
      hidePleaseWaitMessage();
    });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };
  const memoizedFormData = useMemo(() => ({
    name: formData.name,
    number: formData.number,
    option: formData.option
  }), [formData]);
  const showPleaseWaitMessage = () => {
    const submitButton = document.getElementById('submitButton');
    submitButton.style.backgroundColor = '#ccc';
    submitButton.style.color = '#333'; 
    submitButton.style.fontSize = '13px';
    submitButton.style.width = '8vw';
    submitButton.style.cursor = 'not-allowed'; 
    submitButton.innerText = 'Please wait...';
    submitButton.disabled = true;
  };

  const hidePleaseWaitMessage = () => {
    const submitButton = document.getElementById('submitButton');
    submitButton.innerText = 'Submit';
    submitButton.disabled = false;
  };
  
  const style = {
    top: '50%',
    left: '50%',
    width: 300,
    overflowY:"auto",
    textAlign:"center",
    bgcolor: 'white',
    border: 'none',
    borderRadius:"10px",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };
  const locaton=useLocation();
  const[mobileOpen,setMobileOpen] = useState(false);
  const [serviceOpen,setServiceOpen] = useState(false);
  const [bulkSms, setBulkSms] = useState(false);
  const [bulkWhatsapp,setBulkWhatsapp] = useState(false);
  const [Dopen, setdOpen] = React.useState(false);

  const handleDialogClose = () => {
    setdOpen(false);
  };

  // sidebar

  const [open, setOpen] = React.useState(false);
  const [bulkOpen,setBulkOpen] = React.useState(false);
  const [whatsappOpen,setWhatsappOpen] = React.useState(false);
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   handleDialogClose();
  //   console.log("Submitted Data:", { name, phoneNumber});
  // };

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      if (locaton.pathname==='/') {
        setdOpen(true);
      }
    }, 1 * 2 * 1000);
    return () => clearTimeout(timeout);
  }, []);

  const handleClickOpen = () => {
    setOpen(!open);
  };

  const handlebulkOpen = () =>{
    setBulkOpen(!bulkOpen)
  }

  const handleWhatsappOpen = () =>{
    setWhatsappOpen(!whatsappOpen)
  }

// -------------

const handleServiceOpen = () =>{
  setServiceOpen(!serviceOpen)
}

const handlebulkSmsOpen = () =>{
  setBulkSms(!bulkSms)
}

const handlebulkWhatsappOpen = () =>{
  setBulkWhatsapp(!bulkWhatsapp)
}

  const handleDrawertoggle = ()=>{
      setMobileOpen(!mobileOpen);   
  }

  const drawer = (
    <Box onClick={handleDrawertoggle}  sx={{textAlign:'center'}}>

      <Box sx={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
      <Typography variant='h6' component="div" className='header_logo' sx={{ flexGrow: 1, my: 2, width: '100%', display: "flex", justifyContent: 'center', alignItems: 'center' }}>
  <Link to={'/'} style={{ textDecoration: "none" }}>
    <Typography
      sx={{
        fontFamily: 'Osiris',
        fontSize: '27px',
        fontWeight: 400,
        lineHeight: '51.43px',
        textAlign: 'left',
        background: 'linear-gradient(90deg, #38B6FF 0%, #43C69B 100%)',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
        textDecoration: "none"
      }}
    >
      RAPIFY SMS SOLUTIONS
    </Typography>
  </Link>
</Typography>

      </Box>
      <Divider/>

      <List
        sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper',padding:'8px 0px' ,}}
        component="nav"       
       
      >
        <ListItemButton href='/price'>
        <ListItemText primary={<Typography variant="body1" sx={{ fontSize: '18px' }}>Pricing</Typography>}/>
        </ListItemButton>
        
        <ListItemButton onClick={handleClickOpen} >
          <ListItemText primary={<Typography variant="body1" sx={{ fontSize: '18px' }}>Services</Typography>} onClick={handleServiceOpen}/>
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>

        <Collapse in={open} timeout="auto" unmountOnExit>
        <ListItemButton onClick={handlebulkOpen} >
          <ListItemText primary={<Typography variant="body1" sx={{ fontSize: '15px' }}>Bulk SMS</Typography>} onClick={handlebulkSmsOpen}/>
          {bulkOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>

        <Collapse in={bulkOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
            <ListItemButton  href={'/transactional_sms'} sx={{ pl: 3 }}>
              <ListItemText primary={<Typography variant="body1" sx={{ fontSize: '12px' }}>Transactional SMS</Typography>} />
            </ListItemButton>

            <ListItemButton href={'/promotional_sms'} sx={{ pl: 3 }}>
              <ListItemText primary={<Typography variant="body1" sx={{ fontSize: '12px' }}>Promotional SMS</Typography>} />
            </ListItemButton>

            <ListItemButton href={'/high_priority_otp_based'} sx={{ pl: 3 }}>
            <ListItemText primary={<Typography variant="body1" sx={{ fontSize: '12px' }}>High Priority OTP Based (API)</Typography>} />
            </ListItemButton>
          </List>
        </Collapse>
        </Collapse>

        <Collapse in={open} timeout="auto" unmountOnExit>
        <ListItemButton onClick={handleWhatsappOpen} >
          <ListItemText primary={<Typography variant="body1" sx={{ fontSize: '15px' }}>Bulk WhatsApp</Typography>} onClick={handlebulkWhatsappOpen}/>
          {whatsappOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>

        <Collapse in={whatsappOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
            <ListItemButton  href={'/own_number'} sx={{ pl: 3 }}>
            <ListItemText primary={<Typography variant="body1" sx={{ fontSize: '12px' }}>Own Number WhatsApp</Typography>} />
            </ListItemButton>

            <ListItemButton href={'/virtual_whatsapp'} sx={{ pl: 3 }}>
            <ListItemText primary={<Typography variant="body1" sx={{ fontSize: '12px' }}>Virtual WhatsApp</Typography>} />
            </ListItemButton>

            <ListItemButton href={'/green_tick_verified'} sx={{ pl: 3 }}>
            <ListItemText primary={<Typography variant="body1" sx={{ fontSize: '12px' }}>Green Tick Verified</Typography>} />
            </ListItemButton>
           
          </List>
        </Collapse>
        <List component="div" disablePadding>
        <ListItemButton  href={'/dlt_service'} sx={{ }}>
            <ListItemText primary={<Typography variant="body1" sx={{ fontSize: '15px' }}>Dlt Services</Typography>} />
            </ListItemButton>

           
          </List>
        </Collapse>

       

        <ListItemButton href='/about'>
          <ListItemText primary={<Typography variant="body1" sx={{ fontSize: '18px' }}>About Us</Typography>}/>
        </ListItemButton>

        <ListItemButton href='/contact'>
        <ListItemText primary={<Typography variant="body1" sx={{ fontSize: '18px' }}>Contact Us</Typography>}/>
        </ListItemButton>

        <ListItemButton href='http://104.197.83.85:3003/'>
        <ListItemText primary={<Typography variant="body1" sx={{ fontSize: '18px' }}>SignIn</Typography>}/>
        </ListItemButton>
      </List>


    </Box>
  )
  const [age, setAge] = React.useState(''); 
  // const handleChange = (event) => {
  //   setAge(event.target.value);
  // };

  return (
    <>
      <Box sx={{background:'transparent',position:'fixed',width:'100%',minHeight:'10vh',display:'flex',justifyContent:'center',alignItems:'center',zIndex:'999'}}>

          <Box component="nav" sx={{width:'100%',height:'10vh',display:'flex',justifyContent:'space-around',alignItems:'center',background:'#fff',padding:{md:"0px 20px",sm:'0px 10px'}}}>
            
           

            <Typography variant='h6' component={'div'}  sx={{flexGrow:1,"@media (max-width:600px)":{display:'flex',justifyContent:'flex-start',alignItems:'center',marginLeft:'16px'}}}>
             <Box sx={{display:'flex',justifyContent:'flex-start',alignItems:'center',marginLeft:{md:"50px",sm:'10px'}}}>
              <Link to={"/"} style={{ textDecoration: "none"}}><Typography sx={{
              "@media (max-width:636px)":{
              fontSize:"0.9em"},
              fontSize: '1.2em',
              fontWeight: "bolder",
              lineHeight: '51.43px',
              textAlign: 'left',
              background: 'linear-gradient(90deg, #38B6FF 0%, #43C69B 100%)',
              '-webkit-background-clip': 'text',
              '-webkit-text-fill-color': 'transparent' ,textDecoration: "none"}}>RAPIFY SMS SOLUTIONS</Typography></Link>

              <IconButton color='inherit' aria-label='open drawer' edge="start" sx={{margin:"2",display:{xs:"block",sm:"none"},position:'absolute',right:'6px'}} onClick={handleDrawertoggle}>
            <MenuIcon color="primary"/>
          </IconButton>
               
             </Box>
            </Typography>

            <Box className='navigation_menu' sx={{display:{xs:"none",sm:"block",minHeight:'10vh',},marginRight:{lg:'40px'}}}>
              <ul >

              <li><NavLink activeClassName='active' to={"/price"} >Pricing</NavLink></li>
              
              <li><NavLink style={{position:'relative'}}>Services 
              <ArrowDropDownIcon sx={{position:'absolute',top:'-2px'}}/>
              </NavLink>
             
                <ul className='drop_down'> 
                    <li><Link to={"/transactional_sms"}><ArrowForwardIcon className='dropdown_arrow'/>Transactional SMS</Link></li>
                     <li><Link to={"/promotional_sms"}><ArrowForwardIcon className='dropdown_arrow'/>Promotional SMS</Link></li>
                    <li><Link to={"/high_priority_otp_based"}><ArrowForwardIcon className='dropdown_arrow'/>High Priority OTP Based (API)</Link></li>
                    <li><Link to={"/own_number"}><ArrowForwardIcon className='dropdown_arrow'/>Own Number WhatsApp</Link></li>
                    <li><Link to={"/virtual_whatsapp"}><ArrowForwardIcon className='dropdown_arrow'/>Virtual WhatsApp</Link></li>
                    <li><Link to={"/green_tick_verified"}><ArrowForwardIcon className='dropdown_arrow'/>Green Tick Verified</Link></li>
                    <li><Link to={"/dlt_service"}><ArrowForwardIcon className='dropdown_arrow'/>Dlt Services</Link></li>
                </ul>

                {/* <Box className='dropdown_box'>
                  <Box className='ul_box'>
                    <ul className='ul_list'>
                    <li><Link to={"/sms_service"}><ArrowForwardIcon className='dropdown_arrow'/>SMS Services</Link></li>
                     <li><Link to={"/bulk_whatsapp"}><ArrowForwardIcon className='dropdown_arrow'/>Bulk Whatsapp</Link></li>
                    <li><Link to={"/software_solutions"}><ArrowForwardIcon className='dropdown_arrow'/>Software Solutions</Link></li>
                    <li><Link to={"/brand_promotions"}><ArrowForwardIcon className='dropdown_arrow'/>Brand Promotions</Link></li>
                    <li><Link to={"/digital_marketing"}><ArrowForwardIcon className='dropdown_arrow'/>Digital Marketing</Link></li>
                    </ul>
                  </Box>
                  <Box className='ul_box'>
                  <ul className='ul_list'>
                    <li><Link to={"/sms_service"}><ArrowForwardIcon className='dropdown_arrow'/>SMS Services</Link></li>
                     <li><Link to={"/bulk_whatsapp"}><ArrowForwardIcon className='dropdown_arrow'/>Bulk Whatsapp</Link></li>
                    <li><Link to={"/software_solutions"}><ArrowForwardIcon className='dropdown_arrow'/>Software Solutions</Link></li>
                    <li><Link to={"/brand_promotions"}><ArrowForwardIcon className='dropdown_arrow'/>Brand Promotions</Link></li>
                    <li><Link to={"/digital_marketing"}><ArrowForwardIcon className='dropdown_arrow'/>Digital Marketing</Link></li>
                    </ul>
                  </Box>
                </Box> */}
              
              </li>
              
              <li><NavLink activeClassName='active' to={"/about"} >About Us</NavLink></li>
              <li><NavLink activeClassName='active'  to={"/contact"}>Contact Us</NavLink></li>
              
              <button className='signin_btn'><Link to={"http://104.197.83.85:3003/"} target='_blank'>Sign In</Link></button>
              </ul>
            </Box>
          </Box>
       
          <Box component="nav">
            <Drawer variant='temporary' anchor='right' open={mobileOpen || serviceOpen || bulkSms || bulkWhatsapp} onClose={handleDrawertoggle} sx={{display:{sx:"block",sm:"none"},"& .MuiDrawer-paper":{boxSizing:"border-box",width:"200px"}}}>
              {drawer}
            </Drawer>
          </Box>

      
      </Box>


      <Modal
        open={Dopen}
        onClose={handleDialogClose}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'transparent',
          border:"1px solid white"
        }}
        style={
          {
            border:"1px solid white"
          }
        }
        TransitionComponent={Slide} // Use the Slide transition
        TransitionProps={{ direction: 'down' }} // Customize the transition direction
      
        BackdropProps={{ invisible: true }} // Make the backdrop invisible
      
        
       
        
      >
        <Grid  className='css' sx={{width:{lg:'480px',md:'480px',sm:'480px',xs:'308px'},height:{lg:'518px',md:'518px',sm:'500px',xs:'360px'},padding:{md:'65px',sm:'40px',xs:'30px'},border:"1px solid white"}}>

        <IconButton
          onClick={handleDialogClose} 
          sx={{background:"#0DB5A2",
          color:"black",
          width:{sm:"40px",xs:"25px"},
          height:{sm:"40px",xs:"25px"},
          position:"absolute",
          left:{sm:"65%",xs:"70%"},
          top:"23%"}}>
          <CloseIcon/>
        </IconButton>
        <DialogTitle textAlign={"center"} sx={{fontWeight:"600",fontSize:{lg:'22px',md:'22px',sm:'18px',xs:'12px'},lineHeight:{lg:'35px',md:'35px',sm:'30px',xs:'20px'}}} >
          Don't leave without checking special offer just for You!
        </DialogTitle>
        <DialogContent>
          <Box mt={"15px"} style={{marginTop:'-2%',marginLeft:'10%'}}>
          <form ml={"1rem"}>
          <Box mt="5px" >
            <label>
              <Typography fontWeight={"bold"} sx={{fontSize:{lg:'20px',md:'18px',sm:'16px',xs:"13px"},lineHeight:{lg:'30px',md:'35px',sm:'20px',xs:'15px'}}}>Name:</Typography>
              <input 
                style={{width:"90%",height:"30px",borderRadius:'20px',outline:'none',padding:'0px 10px',border:'1px solid grey'}}
                type="text"
                name='name'
                value={memoizedFormData.name} 
                onChange={handleChange}

                required 
              />
            </label>
          </Box>
            <br />
            <Box mt="5px" style={{marginTop:'-2%',marginLeft:'0%'}}>
            <label>
            <Typography fontWeight={"bold"} sx={{fontSize:{lg:'20px',md:'18px',sm:'16px',xs:"13px"},lineHeight:{lg:'30px',md:'35px',sm:'20px',xs:'15px'}}}>Phone Number:</Typography>
              <input 
                style={{width:"90%",height:"30px",borderRadius:'20px',outline:'none',padding:'0px 10px',border:'1px solid grey'}}
                type="number" 
                name="number"
                value={memoizedFormData.number} 
                onChange={handleChange}
                pattern="[0-9]{3}[0-9]{3}[0-9]{4}" 
                required 
                size="small"
                />
              </label>
            </Box>
            <Box mt={"3%"}>
            <Typography fontWeight={"bold"} sx={{fontSize:{lg:'20px',md:'18px',sm:'16px',xs:"13px"},lineHeight:{lg:'30px',md:'35px',sm:'20px',xs:'15px'}}}>Select Option</Typography>
            <FormControl fullWidth >
              <Select
                 name='option'
                 size='s'
                 value={memoizedFormData.option}  
                 onChange={handleChange}
                sx={{height:"30px",width:'90%',borderRadius:'20px',backgroundColor:'white',outline:'none',padding:'0px 10px',border:'1px solid grey'}}
              >
                <MenuItem value={whatsapp}>{whatsapp}</MenuItem>
                <MenuItem value={sms}>{sms}</MenuItem>
              </Select>
            </FormControl>
            </Box>
            <br />
            <DialogActions sx={{justifyContent:"center",marginTop:'-5%',width:"100px"}}>
              <button type='submit' style={{color:"#FFFFFF"}}  className='signin_btn'  id="submitButton" onClick={handleSubmit}>Submit</button>
            </DialogActions>
          </form>
          </Box>
        </DialogContent>

        </Grid>
        
      </Modal>
    </>
  )
}

export default Header
